<template>
  <BaseForm title="NGW Überschreibung verlängern" @onConfirmClicked="onConfirmClicked">
    <form>
      <v-container fluid>
        <div class="text-center">
          <v-dialog v-model="dialog" width="auto">
            <v-card>
              <div style="margin-left: 15px; margin-right: 15px">
                <v-card-text>
                  <div>
                    <h2>
                      Warnung: Diese Überschreibung überschreibt bereits bestehende
                      Überschreibung(en) im selben Zeitraum
                    </h2>
                  </div>
                  <p></p>
                  <p>
                    <b>Neue Überschreibung</b>: {{ this.data.article_displayname }}, Gültig von:
                    {{ this.convertDate(this.data.validFrom) }}, Gültig bis:{{
                      this.convertDate(this.data.validTill)
                    }}
                  </p>
                  <v-data-table
                    :headers="this.OverWriteNGW_headers"
                    :items="this.currentItem"
                    class="elevation-1"
                    density="compact"
                    item-key="name"
                  >
                    <template v-slot:item="i">
                      <tr>
                        <td>{{ i.item.article_displayname }}</td>
                        <td>{{ i.item.marketing_type_cd }}</td>
                        <td>{{ i.item.marketing_sub_type_cd }}</td>
                        <td>{{ i.item.condition_group }}</td>
                        <td :class="{ 'yellow accent-2': i.item.ov_cp_minMax_warn }">
                          {{ i.item.ov_cp_minMax }}
                        </td>
                        <td>{{ i.item.cas_00_r03 }}</td>
                        <td
                          :class="{
                            'yellow accent-2': i.item.ov_cp_contractPayedRatioThreshold_warn,
                          }"
                        >
                          {{ i.item.ov_cp_contractPayedRatioThreshold }}
                        </td>
                        <td :class="{ 'yellow accent-2': i.item.ov_m_minMax_warn }">
                          {{ i.item.ov_m_minMax }}
                        </td>
                        <td>{{ i.item.cas_36_r03 }}</td>
                        <td :class="{ 'yellow accent-2': i.item.ov_m_monthThreshold_warn }">
                          {{ i.item.ov_m_monthThreshold }}
                        </td>
                        <td>{{ i.item.ngwValue.split(',')[5] }}</td>
                        <td>{{ i.item.newNGW.split(',')[5] }}</td>
                        <td>{{ i.item.reason }}</td>
                        <td>{{ i.item.valid_from }}</td>
                        <td>{{ i.item.valid_until }}</td>
                        <td>{{ i.item.controller_confirmed_toshow }}</td>
                        <td>{{ i.item.manager_confirmed_toshow }}</td>
                        <td>{{ i.item.active }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-text>
                  <p><b>bereits vorhandene Überschreibung im selben Zeitraum</b>:</p>
                  <v-data-table
                    :headers="this.OverWriteNGW_headers"
                    :items="this.duplicateChecker"
                    class="elevation-1"
                    density="compact"
                    item-key="name"
                  ></v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div style="margin-right: 3%">
                    Soll diese neue Überschreibung die bereits bestehende überschreiben?
                  </div>
                  <v-btn tile color="grey darken-1" text @click="dialog = false">Abbrechen</v-btn>
                  <v-btn tile color="red darken-1" text @click="confirmDuplicates()"
                    >Bestätigen</v-btn
                  >
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
        </div>
        <v-row align="center" justify="center">
          <v-col cols="2">
            <v-select
              v-model="data.mandator_cd"
              :error-messages="mandatorError"
              label="Mandant"
              :items="mamdatorList"
              v-on:change="changeMandat"
              required
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="data.subpublisher_displayname"
              :error-messages="fachverlagError"
              label="Fachverlag"
              :items="fachverlagList"
              v-on:change="changeFachverlag"
              required
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="data.article_displayname"
              :items="this.articleList"
              label="Artikel"
              v-on:change="changeArticle"
              :error-messages="articleError"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="data.marketing_type_cd"
              :items="Object.keys(this.ngwList).sort()"
              label="Werbeweg"
              :loading="this.loading"
              :error-messages="werbewegError"
              v-on:change="changeWerbeweg"
              required
              class="pa-0 ma-0"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="data.marketing_sub_type_cd"
              :items="this.unterwerbewegList"
              label="Unterwerbeweg"
              :error-messages="unterwerbewegError"
              v-on:change="changeUnterwerbeweg"
              required
              class="pa-0 ma-0"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="data.condition_group"
              :error-messages="conditionError"
              label="Kondition"
              :items="conditionList"
              v-on:change="changeKondition"
              required
              class="pa-0 ma-0"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="4">
            <v-select
              v-model="data.anfo_month"
              :error-messages="anfoMonthError"
              label="Betrachtungsmonat"
              :items="anfoMonthList"
              v-on:change="changeAnfoMonth"
              required
              class="pa-0 ma-0"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-menu
              v-model="menuFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.validFrom"
                  :error-messages="validFromError"
                  label="Gültig von"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="pa-0 ma-0"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.validFrom"
                :allowed-dates="validDate"
                @input="changeDateFrom"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu
              v-model="menuTill"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.validTill"
                  :error-messages="validTillError"
                  label="Gültig bis"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="pa-0 ma-0"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.validTill"
                :allowed-dates="validDate"
                @input="changeDateTill"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div v-if="duplicateCheckerAnfoMonth[0]">
          <p class="font-weight-black">NGW-IST-Zustand (mit Überschreibung)</p>
          <p>Dieser Betrachtungsmonat hat bereits folgende Überschreibung:</p>
          <v-row
            v-for="item in duplicateCheckerAnfoMonth"
            :key="item.id"
            align="center"
            justify="center"
          >
            <v-col cols="12">
              <p class="pa-0 ma-0">
                * Gültig von:{{ item.valid_from }}, bis:{{ item.valid_until }}
              </p>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="item.newNGW.split(',')[0]"
                label="Monatspreis"
                disabled
                class="pa-0 ma-0"
                suffix="€"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="item.newNGW.split(',')[1]"
                label="Einmalpreis"
                disabled
                suffix="€"
                class="pa-0 ma-0"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="item.newNGW.split(',')[2]"
                label="Wandlung in Vertrag Bezahlt"
                disabled
                suffix="%"
                class="pa-0 ma-0"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="item.newNGW.split(',')[3]"
                label="Monate im Abo"
                class="pa-0 ma-0"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="item.newNGW.split(',')[4]"
                label="Ausfallquote"
                disabled
                suffix="%"
                class="pa-0 ma-0"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="item.newNGW.split(',')[5]"
                class="pa-0 ma-0"
                label="NGW IST"
                readonly
                suffix="€"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <div>
          <p class="font-weight-black">NGW-IST-Zustand (ohne Überschreibung)</p>
        </div>
        <v-row align="center" justify="center">
          <v-col cols="2">
            <v-text-field
              v-model="ngwValue[0]"
              label="Monatspreis"
              disabled
              suffix="€"
              class="pa-0 ma-0"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="ngwValue[1]"
              label="Einmalpreis"
              disabled
              suffix="€"
              class="pa-0 ma-0"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="ngwValue[2]"
              label="Wandlung in Vertrag Bezahlt"
              disabled
              suffix="%"
              class="pa-0 ma-0"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="ngwValue[3]"
              class="pa-0 ma-0"
              label="Monate im Abo"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="ngwValue[4]"
              label="Ausfallquote"
              disabled
              suffix="%"
              class="pa-0 ma-0"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="ngwValue[5]"
              class="pa-0 ma-0"
              label="NGW IST"
              readonly
              suffix="€"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div>
          <p class="font-weight-black">NGW-SOLL-Zustand</p>
        </div>
        <v-row align="center" justify="center">
          <v-col cols="2">
            <v-text-field
              v-model="newNGW[0]"
              label="Monatspreis"
              readonly
              suffix="€"
              class="pa-0 ma-0"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="newNGW[1]"
              label="Einmalpreis"
              readonly
              suffix="€"
              class="pa-0 ma-0"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  type="number"
                  v-model="data.ov_cp_contractPayedRatioThreshold"
                  label="Wandlung in Vertrag Bezahlt"
                  background-color="#dce9f5"
                  suffix="%"
                  class="pa-0 ma-0"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <span>Wandlung in Vertrag bezaht</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  type="number"
                  v-model="data.ov_m_monthThreshold"
                  label="Monate im Abo"
                  background-color="#dce9f5"
                  class="pa-0 ma-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span>Monate im Abo (ab AboMax)</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="newNGW[4]"
              label="Ausfallquote"
              readonly
              suffix="%"
              class="pa-0 ma-0"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="newNGW[5]"
              label="NGW SOLL"
              background-color="#E8F5E9"
              readonly
              suffix="€"
              class="pa-0 ma-0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div>
          <p class="font-weight-black">Auswirkung/Differenz durch die Veränderung</p>
        </div>
        <v-row align="center" justify="center">
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.ex_months"
                  label="ggf. Anfomenge"
                  background-color="#dce9f5"
                  class="pa-0 ma-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span>beispielhafte Anfomenge</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2"> </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="ngwSimulator[0]"
                  label="NGW-Sum. IST"
                  readonly
                  suffix="€"
                  background-color="#f5eadc"
                  class="pa-0 ma-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span>NGW-Summe mit IST-Wert</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="ngwSimulator[1]"
                  label="NGW-Sum. Nach"
                  readonly
                  suffix="€"
                  background-color="#f5eadc"
                  class="pa-0 ma-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span>NGW-Summe nach Korrektur</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="ngwSimulator[2]"
                  label="NGW-Diff. je Anfo"
                  readonly
                  background-color="#f5eadc"
                  suffix="€"
                  class="pa-0 ma-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span>NGW-Differenz je Anfo</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="ngwSimulator[3]"
                  label="NGW-Diff. Sum."
                  background-color="#f5eadc"
                  readonly
                  suffix="€"
                  class="pa-0 ma-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span>NGW-Differenz-Summe</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-textarea
              v-model="this.overwriteInfo"
              label="NGW Überschreibung"
              disabled
              rows="1"
              auto-grow
              :background-color="this.infoColor"
              class="pa-0 ma-0"
            ></v-textarea>
            <v-text-field
              v-model="data.reason"
              :error-messages="reasonErrors"
              label="Grund (Freitext)"
              required
              @input="$v.data.reason.$touch()"
              @blur="$v.data.reason.$touch()"
              class="ma-0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="4">
            <v-text-field
              v-model="data.createdBy"
              :error-messages="createdByError"
              label="Erstellt von"
              readonly
              disabled
              class="pa-0 ma-0"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="data.controller"
              :error-messages="controllerError"
              label="Controller"
              :items="controllerEmailLut"
              required
              item-value="code"
              item-text="name"
              v-on:change="changeController"
              class="pa-0 ma-0"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="data.manager"
              :error-messages="managerError"
              label="Manager"
              :items="managerEmailLut"
              required
              item-value="code"
              item-text="name"
              class="pa-0 ma-0"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </BaseForm>
</template>

<script>
import { API } from 'aws-amplify';
import { createOverwriteNGW, updateOverwriteNGW } from '@/graphql/mutations';
import { getOverwriteNGW } from '@/graphql/queries';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

// import approvalListFile from './approvalList.json';

import BaseForm from '@/components/baseCrud/BaseForm.vue';
// import BaseField from '@/components/baseCrud/BaseField.vue';

export default {
  name: 'app',
  mixins: [validationMixin],
  components: {
    BaseForm,
  },
  data() {
    return {
      dialog: false,
      // infoDialog: false,
      infoColor: '#E8F5E9',
      loading: false,
      radios: null,
      menuFrom: false,
      menuTill: false,
      data: {
        reason: '',
        type: 'MONTH_PRICE',
        createdBy: '',
        validFrom: '',
        validTill: '',
        article_displayname: '',
        article_code: '',
        article_cd: '',
        subpublisher_displayname: '',
        marketing_type_cd: '',
        marketing_sub_type_cd: '',
        ngw_ist: null,
        cas_00_r03: null,
        ov_cp: false,
        ov_cp_contractPayedRatioThreshold: null,
        ov_cp_minMax: '',
        cas_36_r03: null,
        ov_m: false,
        ov_m_monthThreshold: null,
        ov_m_minMax: '',
        ex_months: null,
        approvalList: null,
        condition_group: '',
        anfo_month: '',
        delete_marked: false,
        expired: false,
        handled: 0,
        controller: '',
        controller_confirmed: false,
        manager: '',
        manager_confirmed: false,
        mandator_cd: '',
      },
      istKeepValue: Array(3), // [cas_00_r03, cas_36_r03, ngw_36]
      timestamp: 0,
      dropDownValues: null,
      mandatorList: null,
      marketingMailLut: null,
      ngwList: [],
      duplicateChecker: [],
      duplicateCheckerAnfoMonth: [],
      currentItem: [],
      duplicateconfirm: 0,
      ready: false,
    };
  },
  validations() {
    return {
      data: {
        subpublisher_displayname: { required },
        article_displayname: { required },
        marketing_type_cd: { required },
        marketing_sub_type_cd: { required },
        condition_group: { required },
        anfo_month: { required },
        controller: { required },
        manager: { required },
        validFrom: { required },
        validTill: { required },
        reason: { required },
        createdBy: { required },
        mandator_cd: { required },
      },
    };
  },
  async created() {
    console.log('bebgin');
    this.getCurrentItemById(this.currentId);
    this.mamdatorList = Object.keys(this.OverWriteNGW_dropDownValues).sort();
    this.marketingMailLut = this.OverWriteNGW_controllerMailLut;
    this.data.approvalList = this.OverWriteNGW_approvalList;
    this.ready = true;
  },
  watch: {
    'data.article_displayname'() {
      if (this.data.article_displayname && this.data.subpublisher_displayname) {
        this.loading = true;
        this.ngwList = { 'loading...': {} };
        this.getCacheOverwriteNGWs(
          this.data.mandator_cd,
          this.data.subpublisher_displayname,
          this.data.article_displayname
        );
      }
    },
    ngwValue: function () {
      console.log('CHECKER!');
      if (
        this.ngwValue &&
        this.ngwValue.length &&
        this.ngwValue[2] &&
        !this.data.ov_cp_contractPayedRatioThreshold
      ) {
        this.data.ov_cp_contractPayedRatioThreshold = this.ngwValue[2];
        this.data.ov_m_monthThreshold = this.ngwValue[3];
      }
    },
  },
  methods: {
    getCurrentItemById(id) {
      console.log('DEBUG: ' + id);
      API.graphql({
        query: getOverwriteNGW,
        variables: { id },
      }).then((response) => {
        console.log(JSON.stringify(this.item));
        this.item = response.data.getOverwriteNGW;
        this.data.active = this.item.active;
        this.data.anfo_month = this.item.anfo_month;
        this.data.article_displayname = this.item.article_displayname;
        this.data.article_code = this.item.article_code;
        this.data.article_cd = this.item.article_cd;
        this.data.cas_00_r03 = this.item.cas_00_r03;
        this.data.cas_36_r03 = this.item.cas_36_r03;
        this.data.condition_group = this.item.condition_group;
        this.data.controller = this.item.controller;
        this.data.controller_confirmed = this.item.controller_confirmed;
        // this.data.createdBy = this.item.createdBy;
        this.data.createdBy = this.kurzel;
        this.data.delete_marked = this.item.delete_marked;
        this.data.expired = this.item.expired;
        this.data.handled = this.item.handled;
        this.data.mandator_cd = this.item.mandator_cd;
        this.data.manager = this.item.manager;
        this.data.manager_confirmed = this.item.manager_confirmed;
        this.data.marketing_type_cd = this.item.marketing_type_cd
          ? this.item.marketing_type_cd
          : this.item.marketing_sub_type_cd;
        this.data.marketing_sub_type_cd = this.item.marketing_sub_type_cd;
        this.data.ov_cp = this.item.ov_cp;
        this.data.ov_cp_minMax = this.item.ov_cp_minMax;
        this.data.ov_cp_contractPayedRatioThreshold = this.item.ov_cp_contractPayedRatioThreshold;
        this.data.ov_m = this.item.ov_m;
        this.data.ov_m_minMax = this.item.ov_m_minMax;
        this.data.ov_m_monthThreshold = this.item.ov_m_monthThreshold;
        this.data.reason = this.item.reason;
        this.data.subpublisher_displayname = this.item.subpublisher_displayname;
        this.data.timestamp = this.item.timestamp;
        this.data.usrmail = this.item.usrmail;
        this.data.validFrom = this.data.validFrom = new Date(
          new Date(new Date(this.item.valid_until)).getTime() + 1 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .split('T')[0];
        this.data.validTill = new Date(
          new Date(new Date(this.item.valid_until)).getTime() + 30 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .split('T')[0];
        // this.item.article_displayname = this.item.article_displayname.replaceAll('&', '%26');
        API.get(
          'datauiRestAPI',
          `/overwriteNGWCache?mandator_cd=${encodeURIComponent(
            this.item.mandator_cd
          )}&subpublisher_displayname=${encodeURIComponent(
            this.item.subpublisher_displayname
          )}&article_displayname=${encodeURIComponent(this.item.article_displayname)}`,
          {}
        ).then((result) => {
          this.ngwList = JSON.parse(result);
          this.loading = false;
          this.checkDuplicate(
            this.OverWriteNGW_items,
            this.data.article_cd,
            this.data.mandator_cd,
            this.data.marketing_type_cd,
            this.data.marketing_sub_type_cd,
            this.data.condition_group,
            this.data.anfo_month,
            this.convertDate(this.data.validFrom),
            this.convertDate(this.data.validTill)
          );
          // this.data.marketing_cd = this.item.marketing_cd;
          // console.log('DEBUG345: ' + Object.keys(this.wkzList).sort());
        });
      });
    },
    createInputData() {
      this.timestamp = Math.floor(Date.now() / 1000); //Unix timestamp (in seconds)
      const input = {
        active: false,
        anfo_month: this.data.anfo_month,
        article_displayname: this.data.article_displayname,
        article_code: this.ngwValue[6],
        article_cd: this.ngwValue[7],
        cas_00_r03: this.data.cas_00_r03,
        cas_36_r03: this.data.cas_36_r03 ? this.data.cas_36_r03 : 0,
        condition_group: this.data.condition_group,
        controller: this.data.controller,
        controller_confirmed: false,
        createdBy: this.data.createdBy,
        // createdBy: this.kurzel,
        delete_marked: this.data.delete_marked,
        expired: this.data.expired,
        handled: 0,
        manager: this.data.manager,
        mandator_cd: this.data.mandator_cd,
        manager_confirmed: false,
        marketing_type_cd: this.data.marketing_type_cd,
        marketing_sub_type_cd: this.data.marketing_sub_type_cd,
        ov_cp: false,
        ov_cp_minMax: this.minMaxChecker(this.newNGW[2], this.ngwValue[2]),
        ov_cp_contractPayedRatioThreshold: this.data.ov_cp_contractPayedRatioThreshold,
        ov_m: this.data.ov_m,
        ov_m_minMax: this.minMaxChecker(this.newNGW[3], this.ngwValue[3]),
        ov_m_monthThreshold: this.data.ov_m_monthThreshold ? this.data.ov_m_monthThreshold : 0,
        reason: this.data.reason,
        subpublisher_displayname: this.data.subpublisher_displayname,
        timestamp: this.timestamp,
        usrmail: this.data.usrmail,
        valid_from: this.convertDate(this.data.validFrom),
        valid_until: this.convertDate(this.data.validTill),
        ngwValue: this.ngwValue.length ? this.ngwValue.join() : '',
        newNGW: this.newNGW ? this.newNGW.join() : '',
      };
      // console.log(JSON.stringify(input));
      // input['id'] = this.currentId;
      return input;
    },
    async createMethod() {
      let inputData = this.createInputData();
      try {
        await API.graphql({
          query: createOverwriteNGW,
          variables: { input: inputData },
        });
      } catch (err) {
        console.log('DEBUG ERR: ' + JSON.stringify(err));
      }
      try {
        await API.graphql({
          query: updateOverwriteNGW,
          variables: { input: { id: this.currentId, handled: 2 } },
        });
      } catch (err) {
        console.log('DEBUG ERR: cannot update the handled value to 2' + JSON.stringify(err));
      }
      this.$router.push({ name: 'InvoiceOverwriteNGWList' });
      this.$emit('refresh');
    },
    async getCacheOverwriteNGWs(mandator_cd, subpublisher_displayname, article_displayname) {
      // article_displayname = article_displayname.replaceAll('&', '%26');
      this.loading = true;
      let result = await API.get(
        'datauiRestAPI',
        `/overwriteNGWCache?mandator_cd=${encodeURIComponent(
          mandator_cd
        )}&subpublisher_displayname=${encodeURIComponent(
          subpublisher_displayname
        )}&article_displayname=${encodeURIComponent(article_displayname)}`,
        {}
      );
      this.ngwList = JSON.parse(result);
      console.log('DEBUG1234: ' + JSON.stringify(this.ngwList));
      this.loading = false;
    },
    convertDate(date) {
      return this.$moment(date, 'YYYY-MM-DD', 'de').format('YYYY-MM-DD');
    },
    checkDuplicate(
      items,
      article_cd,
      mandator_cd,
      marketing_type_cd,
      marketing_sub_type_cd,
      condition_group,
      anfo_month,
      startTime,
      endTime
    ) {
      console.log('DEBUG DUPLICATE CHECKER: ' + article_cd + ', ' + mandator_cd);
      let duplicatedArticles = items
        ? items.filter(
            (i) =>
              i.article_cd === article_cd &&
              i.mandator_cd === mandator_cd &&
              i.marketing_sub_type_cd === marketing_sub_type_cd &&
              i.marketing_type_cd === marketing_type_cd &&
              i.condition_group === condition_group
          )
        : [];
      // duplicatedArticles = duplicatedArticles.length ? duplicatedArticles.filter((i) => i.anfo_month === anfo_month) : [];
      if (duplicatedArticles.length) {
        duplicatedArticles.forEach((a) => {
          this.dateRangeOverlaps(startTime, endTime, a.valid_from, a.valid_until) &&
            this.duplicateChecker.push(a);
        });
        this.duplicateChecker = this.duplicateChecker.sort((a, b) => b.timestamp - a.timestamp); //[0] is the latest duplicate, sort by created timestamp
        this.duplicateCheckerAnfoMonth = this.duplicateChecker
          ? this.duplicateChecker.filter((i) => i.anfo_month === anfo_month)
          : [];
        // console.log('DUPLICATED: '+JSON.stringify(this.duplicateChecker));
      }
    },
    checkDuplicateWarning(currItem) {
      if (
        this.duplicateChecker.length &&
        currItem.ov_cp_minMax !== this.duplicateChecker[0].ov_cp_minMax
      ) {
        currItem.ov_cp_minMax_warn = true;
      }
      if (
        this.duplicateChecker.length &&
        Number(currItem.ov_cp_contractPayedRatioThreshold) !==
          Number(this.duplicateChecker[0].ov_cp_contractPayedRatioThreshold)
      ) {
        currItem.ov_cp_contractPayedRatioThreshold_warn = true;
      }
      if (
        this.duplicateChecker.length &&
        currItem.ov_m_minMax !== this.duplicateChecker[0].ov_m_minMax
      ) {
        currItem.ov_m_minMax_warn = true;
      }
      if (
        this.duplicateChecker.length &&
        Number(currItem.ov_m_monthThreshold) !== Number(this.duplicateChecker[0].ov_m_monthThreshold)
      ) {
        currItem.ov_m_monthThreshold_warn = true;
      }
      this.currentItem = [currItem];
    },
    onConfirmClicked() {
      this.duplicateChecker = [];
      this.checkDuplicate(
        this.OverWriteNGW_items,
        this.ngwValue[7],
        this.data.mandator_cd,
        this.data.marketing_type_cd,
        this.data.marketing_sub_type_cd,
        this.data.condition_group,
        this.data.anfo_month,
        this.convertDate(this.data.validFrom),
        this.convertDate(this.data.validTill)
      );
      let currItemByClick = this.createInputData();
      console.log('DEBUG123: ' + JSON.stringify(currItemByClick));
      this.checkDuplicateWarning(currItemByClick);
      this.$v.$touch();
      if (this.$v.$anyError || this.validTillError.length || this.validFromError.length || this.loading) {
        return;
      } else if (this.duplicateChecker.length && this.duplicateconfirm === 0) {
        this.dialog = true;
      } else {
        if (
          this.managerEmailLut.filter((e) => e.name === this.data.manager)[0] &&
          this.managerEmailLut.filter((e) => e.name === this.data.manager)[0].email &&
          this.controllerEmailLut.filter((e) => e.name === this.data.controller)[0] &&
          this.controllerEmailLut.filter((e) => e.name === this.data.controller)[0].email
        ) {
          this.createMethod().then(this.sendEmailApproval());
        } else {
          this.createMethod();
        }
      }
    },
    confirmDuplicates() {
      this.duplicateconfirm = 1;
      this.dialog = false;
    },
    dateRangeOverlaps: function (a_start, a_end, b_start, b_end) {
      if (a_start <= b_end && b_start <= a_end) {
        return true;
      } else {
        return false;
      }
    },
    setCreatedBy() {
      if (this.user && this.user.attributes) {
        this.data.createdBy = this.user.attributes.email;
      }
    },
    onCancelClicked() {
      this.$router.go(-1);
    },
    articleDisplayText(item) {
      return `${item.mandatorCode} - ${item.articleCode}`;
    },
    onDateInputChanged(value) {
      const newDate = this.$moment(value, 'YYYY-MM-DD', 'de');
      if (newDate.isValid() || !this.field.mandatory) {
        this.onInputChanged(value);
      }
      this.$forceUpdate();
    },
    minMaxChecker(Soll, Ist) {
      let checker = Soll - Ist;
      if (checker > 0) {
        return 'min';
      } else if (checker < 0) {
        return 'max';
      } else {
        return '';
      }
    },
    sendEmailApproval: function () {
      const input = {
        body: {
          // edit: true,
          managerEmail: this.managerEmailLut.filter((e) => e.name === this.data.manager)[0].email,
          controllerEmail: this.controllerEmailLut.filter((e) => e.name === this.data.controller)[0]
            .email,
          marketingMailApprover: this.marketingMailApprover,
          approvalEmailSubject:
            'Bitte freigeben: NGW-Überschreibung für ' +
            this.data.article_displayname +
            '/' +
            this.data.marketing_sub_type_cd +
            '/' +
            this.data.condition_group +
            '/' +
            this.data.validTill,
          approvalEmailContent: `Hallo,\n\nes liegt eine neue NGW-Überschreibung vor, mit der Bitte um Freigabe:\n\nArtikel: ${
            this.data.article_displayname
          }\nWerbeweg: ${this.data.marketing_type_cd}\nUnterwerbeweg: ${this.data.marketing_sub_type_cd}\nKondition: ${
            this.data.condition_group
          }\nGültig von: ${this.data.validFrom}\nGültig bis: ${
            this.data.validTill
          }\nVeränderung des NGWs von ${this.ngwValue[5]}€ auf ${
            this.newNGW[5]
          }€ \nvoraussichtlicher Gesamtwert ${this.ngwSimulator[3]}€\n\n${this.emailFragment(
            this.ngwValue[2],
            this.newNGW[2],
            this.ngwValue[3],
            this.newNGW[3]
          )}\nGrund: ${this.data.reason}\n\nHier gelangst du zum freizugebenden Überschreibungs-Datensatz:\nBitte die Überschreibung prüfen und freigeben.\nLink: `,
          stillMailSubject:
            'INFO: NGW-Überschreibung für ' +
            this.data.article_displayname +
            '/' +
            this.data.marketing_sub_type_cd +
            '/' +
            this.data.condition_group +
            '/' +
            this.data.validTill,
          stillMailContent: `Hallo,\n\nes liegt eine neue NGW-Überschreibung vor:\n\nArtikel: ${
            this.data.article_displayname
          }\nWerbeweg: ${this.data.marketing_type_cd}\nUnterwerbeweg: ${this.data.marketing_sub_type_cd}\nKondition: ${
            this.data.condition_group
          }\nGültig von: ${this.data.validFrom}\nGültig bis: ${
            this.data.validTill
          }\nVeränderung des NGWs von ${this.ngwValue[5]}€ auf ${
            this.newNGW[5]
          }€, voraussichtliche Gesamtveränderung ${
            this.ngwSimulator[3] ? Number(this.ngwSimulator[3]) : 0
          } € bei Anfomenge ${Number(this.data.ex_months)}\n\n${this.emailFragment(
            this.ngwValue[2],
            this.newNGW[2],
            this.ngwValue[3],
            this.newNGW[3]
          )}\nGrund: ${this.data.reason}\n\nHier gelangst du zum Überschreibungs-Datensatz:\nLink: `,
          mailFoot: `ngw/overwriteNGW?id=${this.timestamp}\n\nViele Grüße,\n${this.data.createdBy}`,
        },
      };
      // console.log('debug LUT: ' + this.marketingMailApprover);
      console.log('marketing still mail: ', this.marketingMailApprover);
      API.post('datauiRestAPI', '/sendApprovalEmail', input)
        .then((result) => {
          console.log('Email sent!' + JSON.stringify(result));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emailFragment: function (wandlungIST, wandlungSOLL, monateIST, monateSOLL) {
      console.log(
        'debug1: ' + this.ngwValue[2] + ', ' + this.newNGW[2] + ', ' + this.data.ov_cp_minMax
      );
      console.log(
        'debug2: ' + this.ngwValue[3] + ', ' + this.newNGW[3] + ', ' + this.data.ov_m_minMax
      );
      if (
        parseFloat(wandlungIST) !== parseFloat(wandlungSOLL) &&
        parseFloat(monateIST) !== parseFloat(monateSOLL)
      ) {
        return `Überschrieben wurde die „Wandlung in den Vertag“ von ${wandlungIST}% auf ${
          this.minMaxChecker(wandlungSOLL, wandlungIST) == 'min' ? 'mindestens' : 'maximal'
        } ${wandlungSOLL}%.\nÜberschrieben wurde die „Monate im Abo“ von ${monateIST} auf ${
          this.minMaxChecker(monateSOLL, monateIST) == 'min' ? 'mindestens' : 'maximal'
        } ${monateSOLL}.`;
      } else if (
        parseFloat(wandlungIST) !== parseFloat(wandlungSOLL) &&
        parseFloat(monateIST) === parseFloat(monateSOLL)
      ) {
        return `Überschrieben wurde die „Wandlung in den Vertag“ von ${wandlungIST}% auf ${
          this.minMaxChecker(wandlungSOLL, wandlungIST) == 'min' ? 'mindestens' : 'maximal'
        } ${wandlungSOLL}%.`;
      } else if (
        parseFloat(wandlungIST) === parseFloat(wandlungSOLL) &&
        parseFloat(monateIST) !== parseFloat(monateSOLL)
      ) {
        return `Überschrieben wurde die „Monate im Abo“ von ${monateIST} auf ${
          this.minMaxChecker(monateSOLL, monateIST) == 'min' ? 'mindestens' : 'maximal'
        } ${monateSOLL}.“`;
      }
    },
    infoFragment: function (wandlungIST, wandlungSOLL, monateIST, monateSOLL) {
      console.log(
        'debug1: ' + this.ngwValue[2] + ', ' + this.newNGW[2] + ', ' + this.data.ov_cp_minMax
      );
      console.log(
        'debug2: ' + this.ngwValue[3] + ', ' + this.newNGW[3] + ', ' + this.data.ov_m_minMax
      );
      if (
        wandlungSOLL &&
        monateSOLL &&
        parseFloat(wandlungIST) !== parseFloat(wandlungSOLL) &&
        parseFloat(monateIST) !== parseFloat(monateSOLL)
      ) {
        this.infoColor = '#E8F5E9';
        return `Überschrieben werden soll: die „Wandlung in den Vertag“ von ${wandlungIST}% auf ${
          this.minMaxChecker(wandlungSOLL, wandlungIST) == 'min' ? 'mindestens' : 'maximal'
        } ${wandlungSOLL}%,\ndie „Monate im Abo“ von ${monateIST} auf ${
          this.minMaxChecker(monateSOLL, monateIST) == 'min' ? 'mindestens' : 'maximal'
        } ${monateSOLL}`;
      } else if (
        wandlungSOLL &&
        parseFloat(wandlungIST) !== parseFloat(wandlungSOLL) &&
        parseFloat(monateIST) === parseFloat(monateSOLL)
      ) {
        this.infoColor = '#E8F5E9';
        return `Überschrieben werden soll: die „Wandlung in den Vertag“ von ${wandlungIST}% auf ${
          this.minMaxChecker(wandlungSOLL, wandlungIST) == 'min' ? 'mindestens' : 'maximal'
        } ${wandlungSOLL}%.`;
      } else if (
        monateSOLL &&
        parseFloat(wandlungIST) === parseFloat(wandlungSOLL) &&
        parseFloat(monateIST) !== parseFloat(monateSOLL)
      ) {
        this.infoColor = '#E8F5E9';
        return `Überschrieben werden soll: die „Monate im Abo“ von ${monateIST} auf ${
          this.minMaxChecker(monateSOLL, monateIST) == 'min' ? 'mindestens' : 'maximal'
        } ${monateSOLL}.“`;
      } else if (this.newNGW[5] && this.newNGW[5] == this.istKeepValue[2]) {
        this.infoColor = '#ffcccc';
        return 'Wähle einen anderen Überschreibungswert, IST- und SOLL- Wert ist identisch!';
      }
    },
    changeMandat() {
      this.data.subpublisher_displayname = null;
      this.data.article_displayname = null;
      this.ngwList = [];
      this.data.marketing_sub_type_cd = null;
      this.data.condition_group = null;
      this.data.anfo_month = null;
      this.data.cas_00_r03 = null;
      this.data.cas_36_r03 = null;
      this.data.ov_cp_contractPayedRatioThreshold = null;
      this.data.ov_m_monthThreshold = null;
      this.duplicateCheckerAnfoMonth = [];
    },
    changeFachverlag() {
      this.data.article_displayname = null;
      this.ngwList = [];
      this.data.marketing_sub_type_cd = null;
      this.data.condition_group = null;
      this.data.anfo_month = null;
      this.data.cas_00_r03 = null;
      this.data.cas_36_r03 = null;
      this.data.ov_cp_contractPayedRatioThreshold = null;
      this.data.ov_m_monthThreshold = null;
      this.duplicateCheckerAnfoMonth = [];
    },
    changeArticle() {
      this.ngwList = [];
      this.data.marketing_sub_type_cd = null;
      this.data.condition_group = null;
      this.data.anfo_month = null;
      this.data.cas_00_r03 = null;
      this.data.cas_36_r03 = null;
      this.data.ov_cp_contractPayedRatioThreshold = null;
      this.data.ov_m_monthThreshold = null;
      this.duplicateChecker = [];
      this.duplicateCheckerAnfoMonth = [];
      this.duplicateconfirm = 0;
    },
    changeWerbeweg() {
      this.data.marketing_sub_type_cd = null;
      this.data.condition_group = null;
      this.data.anfo_month = null;
      this.data.cas_00_r03 = null;
      this.data.cas_36_r03 = null;
      this.data.ov_cp_contractPayedRatioThreshold = null;
      this.data.ov_m_monthThreshold = null;
      this.duplicateCheckerAnfoMonth = [];
    },
    changeUnterwerbeweg() {
      this.data.condition_group = null;
      this.data.anfo_month = null;
      this.data.cas_00_r03 = null;
      this.data.cas_36_r03 = null;
      this.data.ov_cp_contractPayedRatioThreshold = null;
      this.data.ov_m_monthThreshold = null;
      this.duplicateCheckerAnfoMonth = [];
    },
    changeKondition() {
      this.data.anfo_month = null;
      this.data.cas_00_r03 = null;
      this.data.cas_36_r03 = null;
      this.data.ov_cp_contractPayedRatioThreshold = null;
      this.data.ov_m_monthThreshold = null;
      this.duplicateCheckerAnfoMonth = [];
    },
    changeAnfoMonth() {
      this.data.ov_cp_contractPayedRatioThreshold = null;
      this.data.ov_m_monthThreshold = null;
      this.data.validFrom = '';
      this.data.validTill = '';
      this.duplicateChecker = Array(8);
      this.duplicateCheckerAnfoMonth = [];
    },
    changeDateFrom() {
      this.menuFrom = false;
      this.duplicateChecker = [];
      this.duplicateconfirm = 0;
      this.duplicateCheckerAnfoMonth = [];
    },
    changeDateTill() {
      this.menuTill = false;
      this.duplicateChecker = [];
      this.duplicateCheckerAnfoMonth = [];
      this.duplicateconfirm = 0;
      this.checkDuplicate(
        this.OverWriteNGW_items,
        this.ngwValue[7],
        this.data.mandator_cd,
        this.data.marketing_type_cd,
        this.data.marketing_sub_type_cd,
        this.data.condition_group,
        this.data.anfo_month,
        this.convertDate(this.data.validFrom),
        this.convertDate(this.data.validTill)
      );
    },
    changeController() {
      this.data.manager = null;
      this.data.cas_00_r03 = null;
      this.data.cas_36_r03 = null;
    },
    validDate(val) {
      let dateNow = new Date(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      ).getTime();
      let checkerFrom = new Date(dateNow - 3 * 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10);
      let checkerTill = new Date(dateNow + 6 * 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10);
      return val >= checkerFrom && val <= checkerTill;
    },
    ngwDataCalculator(price_flex_month, price_fix, cas_00_r03, cas_36_r03, outage_rate) {
      console.log(
        price_flex_month +
          ', ' +
          price_fix +
          ', ' +
          cas_00_r03 +
          ', ' +
          cas_36_r03 +
          ', ' +
          outage_rate
      );
      if (this.data.condition_group && this.data.condition_group.includes('GZG')) {
        console.log(
          'CHECKER GCG! ' +
            (parseFloat(price_flex_month) * parseFloat(cas_36_r03) +
              parseFloat(price_fix) +
              (parseFloat(price_flex_month) * 3) / 2) *
              parseFloat(cas_00_r03) *
              (1 - parseFloat(outage_rate))
        );
        return (
          (parseFloat(price_flex_month) * parseFloat(cas_36_r03) +
            parseFloat(price_fix) +
            (parseFloat(price_flex_month) * 3) / 2) *
          parseFloat(cas_00_r03) *
          (1 - parseFloat(outage_rate))
        );
      } else {
        return (
          (parseFloat(price_flex_month) * parseFloat(cas_36_r03) + parseFloat(price_fix)) *
          parseFloat(cas_00_r03) *
          (1 - parseFloat(outage_rate))
        );
      }
    },
  },
  computed: {
    overwriteInfo() {
      return this.infoFragment(this.ngwValue[2], this.newNGW[2], this.ngwValue[3], this.newNGW[3]);
    },
    controllerEmailLut() {
      if (!this.data.subpublisher_displayname) {
        return [];
      } else {
        let controllerEmailLut = [];
        if (this.data.approvalList[this.data.subpublisher_displayname]) {
          this.data.approvalList[this.data.subpublisher_displayname]['Controller'].forEach((e) => {
            controllerEmailLut.push({ name: e.split('@')[0], email: e });
          });
        }
        return controllerEmailLut.sort();
      }
    },
    managerEmailLut() {
      if (!this.data.subpublisher_displayname) {
        return [];
      } else {
        let managerEmailLut = [];
        if (this.data.subpublisher_displayname) {
          this.data.approvalList[this.data.subpublisher_displayname]['Manager'].forEach((e) => {
            managerEmailLut.push({ name: e.split('@')[0], email: e });
          });
        }
        return managerEmailLut.sort();
      }
    },
    marketingMailApprover() {
      if (!this.data.subpublisher_displayname || !this.marketingMailLut) {
        return [];
      } else {
        let test = [];
        this.marketingMailLut.forEach((i) => {
          if (i.subpublisher_displayname === this.data.subpublisher_displayname) {
            test = i.controller;
          }
        });
        return test;
      }
    },
    fachverlagList() {
      if (!this.data.mandator_cd) {
        return [];
      } else {
        let temp = [];
        temp = Object.keys(this.OverWriteNGW_dropDownValues[this.data.mandator_cd]);
        return temp.sort();
      }
    },
    articleList() {
      if (!this.data.subpublisher_displayname) {
        return [];
      } else {
        let temp = [];
        temp =
          this.OverWriteNGW_dropDownValues[this.data.mandator_cd][
            this.data.subpublisher_displayname
          ];
        // console.log('debug fachverlaglist: ' + JSON.stringify(this.OverWriteNGW_dropDownValues));
        return temp.sort();
      }
    },
    unterwerbewegList() {
      if (!this.data.marketing_type_cd || !this.ngwValue) {
        return [];
      } else {
        return Object.keys(this.ngwList[this.data.marketing_type_cd]).sort();
      }
    },
    conditionList() {
      if (
        !this.data.subpublisher_displayname ||
        !this.data.article_displayname ||
        !this.data.marketing_type_cd ||
        !this.data.marketing_sub_type_cd ||
        !this.ngwValue
      ) {
        return [];
      } else {
        return Object.keys(
          this.ngwList[this.data.marketing_type_cd][this.data.marketing_sub_type_cd]
        ).sort();
      }
    },
    anfoMonthList() {
      if (
        !this.data.subpublisher_displayname ||
        !this.data.article_displayname ||
        !this.data.marketing_type_cd ||
        !this.data.marketing_sub_type_cd ||
        !this.data.condition_group ||
        !this.ngwValue
      ) {
        return [];
      } else {
        return Object.keys(
          this.ngwList[this.data.marketing_type_cd][this.data.marketing_sub_type_cd][
            this.data.condition_group
          ]
        )
          .sort()
          .reverse();
      }
    },
    ngwValue() {
      if (
        !this.data.subpublisher_displayname ||
        !this.data.article_displayname ||
        !this.data.marketing_type_cd ||
        !this.data.marketing_sub_type_cd ||
        !this.data.condition_group ||
        !this.data.anfo_month ||
        this.loading
      ) {
        console.log('DEBUG: Empty!!!');
        return '';
      } else {
        console.log('DEBUG: NOT Empty!!!');
        let originalValue =
          this.ngwList[this.data.marketing_type_cd][this.data.marketing_sub_type_cd][
            this.data.condition_group
          ][this.data.anfo_month];
        console.log('ngwValue original: ' + originalValue);
        let newValue = originalValue.map((val, i) => {
          if (i === 2 || i === 4) {
            i === 2 && (this.istKeepValue[0] = parseFloat(val).toFixed(4));
            return (parseFloat(val) * 100).toFixed(2);
          } else if (i === 3) {
            this.istKeepValue[1] = parseFloat(parseFloat(val) / originalValue[2]).toFixed(2);
            return parseFloat(parseFloat(val) / originalValue[2]).toFixed(2);
          } else if (i === 6 || i === 7) {
            return val;
          } else {
            i === 5 && (this.istKeepValue[2] = parseFloat(val).toFixed(2));
            return parseFloat(val).toFixed(2);
          }
        });
        console.log('this.istKeepValue: ' + this.istKeepValue);
        console.log('ngwValue monate in abo / wandlung in vertrag: ' + newValue);
        return newValue;
      }
    },
    newNGW() {
      let newValue = Array(6);
      newValue[0] = this.ngwValue[0];
      newValue[1] = this.ngwValue[1];
      newValue[2] = this.data.ov_cp_contractPayedRatioThreshold
        ? parseFloat(this.data.ov_cp_contractPayedRatioThreshold).toFixed(2)
        : 0;
      newValue[3] = this.data.ov_m_monthThreshold
        ? parseFloat(this.data.ov_m_monthThreshold).toFixed(2)
        : 0;
      newValue[4] = this.ngwValue[4];
      if (this.istKeepValue[0]) {
        if (
          newValue[2] === parseFloat(this.istKeepValue[0] * 100).toFixed(2) &&
          newValue[3] === this.istKeepValue[1]
        ) {
          console.log('NGW-IST0: ' + this.istKeepValue);
          let checker = this.ngwDataCalculator(
            newValue[0],
            newValue[1],
            newValue[2] / 100,
            newValue[3],
            newValue[4] / 100
          ).toFixed(2);
          console.log(
            'NGW-SOLL0: ' +
              [
                (parseFloat(newValue[2]) / 100).toFixed(4),
                parseFloat(newValue[3]).toFixed(2),
                checker,
              ]
          );
          newValue[5] = this.istKeepValue[2];
        } else {
          newValue[5] = this.ngwDataCalculator(
            newValue[0],
            newValue[1],
            newValue[2] / 100,
            newValue[3],
            newValue[4] / 100
          ).toFixed(2);
          console.log('NGW- IST: ' + this.istKeepValue);
          console.log(
            'NGW-SOLL: ' +
              [
                (parseFloat(newValue[2]) / 100).toFixed(2),
                parseFloat(newValue[3]).toFixed(2),
                parseFloat(newValue[5]).toFixed(2),
              ]
          );
        }
      } else {
        newValue[5] = null;
      }
      return newValue;
    },
    ngwSimulator() {
      if (!this.data.ex_months) {
        return [];
      }
      let simulator = Array(4);
      simulator[0] = this.ngwValue[5] * this.data.ex_months; // NGW IST * month
      if (this.newNGW[5]) {
        simulator[1] = this.newNGW[5] * this.data.ex_months; // NGW SOLL * month
        simulator[2] = this.newNGW[5] - this.ngwValue[5]; // NWG SOLL - NGW IST
        simulator[3] = simulator[1] - simulator[0];
        simulator = simulator.map((i) => i.toFixed(2));
      }

      return simulator;
    },
    mandatorError() {
      const errors = [];
      if (!this.$v.data.mandator_cd.$dirty) return errors;
      !this.$v.data.mandator_cd.required && errors.push('Is required.');
      // console.log('mandatorError'+errors);
      return errors;
    },
    fachverlagError() {
      const errors = [];
      if (!this.$v.data.subpublisher_displayname.$dirty) return errors;
      !this.$v.data.subpublisher_displayname.required && errors.push('Is required.');
      // console.log('fachverlagError'+errors);
      return errors;
    },
    articleError() {
      const errors = [];
      if (!this.$v.data.article_displayname.$dirty) return errors;
      !this.$v.data.article_displayname.required && errors.push('Is required.');
      // console.log('articleError'+errors);
      return errors;
    },
    werbewegError() {
      const errors = [];
      if (!this.$v.data.marketing_sub_type_cd.$dirty) return errors;
      !this.$v.data.marketing_sub_type_cd.required && errors.push('Is required.');
      // console.log('mandatorError'+errors);
      return errors;
    },
    unterwerbewegError() {
      const errors = [];
      if (!this.$v.data.marketing_sub_type_cd.$dirty) return errors;
      !this.$v.data.marketing_sub_type_cd.required && errors.push('Is required.');
      // console.log('mandatorError'+errors);
      return errors;
    },
    conditionError() {
      const errors = [];
      if (!this.$v.data.condition_group.$dirty) return errors;
      !this.$v.data.condition_group.required && errors.push('Is required.');
      // console.log('mandatorError'+errors);
      return errors;
    },
    anfoMonthError() {
      const errors = [];
      if (!this.$v.data.anfo_month.$dirty) return errors;
      !this.$v.data.anfo_month.required && errors.push('Is required.');
      // console.log('mandatorError'+errors);
      return errors;
    },
    controllerError() {
      const errors = [];
      if (!this.$v.data.controller.$dirty) return errors;
      !this.$v.data.controller.required && errors.push('Is required.');
      // console.log('controllerError'+errors);
      return errors;
    },
    managerError() {
      const errors = [];
      if (!this.$v.data.manager.$dirty) return errors;
      !this.$v.data.manager.required && errors.push('Is required.');
      // console.log('managerError'+errors);
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.data.reason.$dirty) return errors;
      !this.$v.data.reason.required && errors.push('Is required.');
      // console.log('reasonError'+errors);
      return errors;
    },
    validTillError() {
      const errors = [];
      if (!this.$v.data.validTill.$dirty) return errors;
      !this.$v.data.validTill.required && errors.push('Is required.');
      if (this.$v.data.validTill.$dirty && this.$v.data.validFrom.$dirty) {
        let till = new Date(this.data.validTill).getTime();
        let from = new Date(this.data.validFrom).getTime();
        let checker = till - from;

        if (checker < 0) {
          this.$v.data.validTill.required &&
            errors.push('Beginning date must be earlier than ending date');
          return errors;
        }
      }
      // console.log('validTillError: '+errors);
      return errors;
    },
    validFromError() {
      const errors = [];
      if (!this.$v.data.validFrom.$dirty) return errors;
      !this.$v.data.validFrom.required && errors.push('Is required.');
      if (this.$v.data.validTill.$dirty && this.$v.data.validFrom.$dirty) {
        let till = new Date(this.data.validTill).getTime();
        let from = new Date(this.data.validFrom).getTime();
        let checker = till - from;

        if (checker < 0) {
          this.$v.data.validFrom.required &&
            errors.push('Beginning date must be earlier than ending date');
          return errors;
        }
      }
      // console.log('validFromError: '+errors);
      return errors;
    },
    createdByError() {
      const errors = [];
      if (!this.$v.data.createdBy.$dirty) return errors;
      !this.$v.data.createdBy.required && errors.push('Is required.');
      // console.log('createdByError'+errors);
      return errors;
    },

    ...mapGetters([
      'user',
      'kurzel',
      'OverWriteNGW_dropDownValues',
      'OverWriteNGW_approvalList',
      'OverWriteNGW_items',
      'OverWriteNGW_headers',
      'OverWriteNGW_controllerMailLut',
    ]),
    currentId() {
      return this.$route.params.id;
    },
  },
};
</script>
